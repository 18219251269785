import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { generatePostTagLink } from '../../../utils/post'
import { CloseIcon } from '../icons/CloseIcon'

const BulletTagContainer = styled.a`
display: flex;
width: max-content;
height: 40px;
justify-content: center;
align-items: center;
padding: 0 20px;
border-radius: 30px;
border: 2px solid #00A8E0;
text-transform: uppercase;
color: #00A8E0;
font-size: 14px;
font-weight: 400;
margin-right: 10px;
will-change: background-color;
transition: all ease-in-out 200ms;
text-decoration: none;

@media only screen and (min-width: 710px) {
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 168, 224, 0.1);
  }
}
`

const SelectedBulletTagContainer = styled.div`
  width: max-content;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  text-transform: uppercase;
  color: #00A8E0;
  font-size: 14px;
  font-weight: 400;
  border-radius: 30px;
  border: 2px solid #00A8E0;
  background-color: rgba(0, 168, 224, 0.1);
  
  @media only screen and (min-width: 710px) {
    cursor: pointer;
    margin: 0 20px;
  }
`

const CloseIconContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 20px;
  margin-right: -10px;
  padding: 2px;
  background-color: #00A8E0;
  border-radius: 30px;
  cursor: pointer;

  & > * {
    filter: invert(100%);
  }

`

interface IBulletTag {
  text: string,
  slug?: string
}

export const BulletTag = ({ text, slug }: IBulletTag) => (
  <>
    <BulletTagContainer href={generatePostTagLink(slug)} data-testid="BulletTagContainer">
      {text}
    </BulletTagContainer>
  </>
)

export const SelectedBulletTag = ({ text }: IBulletTag) => (
  <>
    <SelectedBulletTagContainer data-testid="SelectedBulletTagContainer">
      {text}
      <CloseIconContainer href="/" data-testid="CloseIconContainer">
        <CloseIcon />
      </CloseIconContainer>
    </SelectedBulletTagContainer>
  </>
)
