import styled, { css } from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { colors } from '../../../styles/colors'
import { replaceRawHtmlEntities } from '../../../utils/replaceRawHtml'

const CardHoverBoxShadow = css`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 20px #D2D2D7;
`

const BaseCard = styled.div`
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px #D2D2D7;
  height: 340px;
  overflow: hidden;
  border: 1px solid #D2D2D7;

  ${media.deskGUp} {
    border-radius: 15px;
  }
`

const BaseContainer = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${colors.gray13};
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
`

const PressReleaseContainer = styled(BaseContainer)`
  ${media.deskGUp} {
    flex-direction: row;
  }
`

const BaseContent = styled.div`
  display: flex;
  flex-direction: column;
  @supports (-webkit-touch-callout: none) {
    height: 100px;
  }
  height: 100%;

  ${media.deskGUp} {
    padding: 24px;
  }
`

const MediumContent = styled(BaseContent)`
  padding: 15px 15px 0px 15px;
  transition: transform 300ms ease-in-out;
  background-color: white;

  ${media.deskGUp} {
    padding: 15px 24px 0;
  }
`

const MediumCreationDate = styled(BaseContent)`
  padding: 0 10px 15px 15px;
  background-color: white;

  ${media.deskGUp} {
    padding-top: 0;
    padding-left: 24px;
  }
`

const AssetContent = styled(BaseContent)`
  padding: 16px;
  height: 100%;

  ${media.deskGUp} {
    padding: 16px;
  }
`

const BaseCardImg = styled.img`
  width: 100%;
  object-fit: cover;
  transition-property: transform, filter;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
`

const MediumCardImg = styled(BaseCardImg)`
  height: 180px;

  ${media.deskGUp} {
    border-radius: 15px 15px 0 0;
  }
`

const AssetCardImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-bottom: 1px solid #E3E3E3;

  ${media.tabMUp} {
    height: 185px;
    min-height: 185px;
    border-radius: 15px 15px 0 0;
  }
`

const AssetCardImg = styled(BaseCardImg)`
  height: 100%;
`

const AssetCardImgOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: opacity 300ms ease;
`

const BaseCardText = styled.p`
  padding: 0;
  margin: 0;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #212121;
  font-size: 18px;
  line-height: 120%;
`

const HighlightExcerpt = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #757575;
`

const MediumCardText = styled(BaseCardText)`
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;

  ${media.deskGUp} {
    font-weight: normal;
  }
`

const SmallCardText = styled(BaseCardText)`
  font-size: 16px;
  
  ${media.tabMUp} {
    line-height: 19px;
  }
`

const BaseCategory = styled.p`
  margin: 0;
  text-decoration: none;
  color: ${colors.irisBlue};
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
`

const MediumExcerpt = styled.p`
  margin-top: 0px;
  margin-bottom: -80px;

  color: #757575;
  font-size: 14px;
  line-height: 18px;

  opacity: 0;
  visibility: hidden;
  transition: opacity 310ms ease-in-out;
`

const SmallCategory = styled(BaseCategory)`
  font-size: 16px;
  line-height: 17px;
  margin-top: auto;
`

const CreationDate = styled.p`
  margin: 0;
  margin-top: auto;
  color: #757575;
  font-size: 14px;

  ${media.tabMUp} {
    font-size: 12px;
  }
`

const MediumFlexibleCard = styled(BaseCard)`
  width: 100%;
  height: 340px;
  border-radius: 15px;
  margin: 8px 0; //design by RChiesa

  @media only screen and (min-width: 800px) {
    margin: unset; //design by RChiesa
  }

  transition-property: box-shadow, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;

  box-shadow: unset;

  will-change: box-shadow, transform, visibility, opacity, filter;

  ${media.deskGUp} {
    flex-direction: column;

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 20px #D2D2D7;

      ${MediumContent} {
        transform: translateY(-50px);
        overflow: visible;
      }

      ${MediumExcerpt} {
        visibility: visible;
        opacity: 1;
        margin-top: 10px;
      }

      img {
        filter: brightness(70%);
        transform: scale3d(1.04, 1.04, 1);
      }
    }
  }
`

const MediumCard = styled(BaseCard) <{ noShadowOnOver: boolean }>`
  margin-right: 10px;
  margin-left: 10px;
  width: 245px;
  height: 300px;
  border-radius: 15px;

  box-shadow: unset;

  transition-property: box-shadow, transform, opacity;
  transition: 300ms ease-in-out;

  will-change: box-shadow, transform, visibility, opacity, filter;

  ${media.deskGUp} {
    width: 390px;
    height: 340px;
    margin: 0 20px 20px 10px;
    flex-direction: column;

    &:first-of-type {
      margin: 0 20px 20px 0;
    }

    &:hover {
      ${props => !props.noShadowOnOver && css`
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 20px #D2D2D7;
      `};

      ${MediumContent} {
        transform: translateY(-50px);
        overflow: visible;
      }

      ${MediumExcerpt} {
        visibility: visible;
        opacity: 1;
        margin-top: 15px;
      }

      img {
        filter: brightness(70%);
        transform: scale3d(1.04, 1.04, 1);
      }
    }
  }
`

const AssetCard = styled(BaseCard)`
  width: 300px;
  height: 290px;
  border-radius: 15px;
  margin: 8px;

  transition: all 300ms ease;
  will-change: transform, opacity, box-shadow;

  ${media.mobLUp} {
    margin: 15px;
  }

  ${media.tabMUp} {
    width: 350px;
    height: 300px;

    &:hover {
      ${CardHoverBoxShadow};

      ${AssetCardImg} {
        transform: scale3d(1.05, 1.05, 1);
      }

      ${AssetCardImgOverlay} {
        opacity: 0.1;
      }
    }
  }
`

const PressReleaseCard = styled(BaseCard)`
  width: 100%;
  max-width: 290px;
  height: 465px;
  border-radius: 15px;
  margin: 20px 10px;
  transition-property: box-shadow, transform;
  transition: 300ms ease-in-out;
  box-shadow: unset;
  will-change: box-shadow, transform, filter;

  ${media.mobLUp} {
    max-width: 450px;
  }

  ${media.tabMUp} {
    max-width: 520px;

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 20px #D2D2D7;

      img {
        transform: scale3d(1.04, 1.04, 1);
        filter: brightness(70%);
      }
    }
  }

  ${media.deskGUp} {
    margin: 20px 16px;
    max-width: 1110px;
    padding-bottom: 0;
    flex-direction: row;
    height: 375px;
  }
`

const PressReleaseCardImg = styled.div`
  min-height: 180px;
  overflow: hidden;
  width: 100%;

  ${media.deskGUp} {
    width: 55%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition-property: transform, filter;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
  }
`

const PressReleaseContent = styled(BaseContent)`
  padding: 16px;
  transition: transform 200ms ease-in-out;

  ${media.deskGUp} {
    padding-right: 60px;
  }
`

const PressReleaseCardText = styled(BaseCardText)`
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;

  ${media.deskGUp} {
    font-size: 24px;
    line-height: 31px;
  }
`

interface ICardProps {
  image: string,
  image_webp?: string,
  videoThumb?: string,
  title: string,
  href: string,
  excerpt?: string,
  creationDate?: string,
}

interface IMediumCardProps extends ICardProps {
  noShadowOnOver?: boolean
}

interface IPressCardProps {
  image: string,
  image_webp?: string,
  videoThumb?: string,
  title: string,
  href: string,
  excerpt: string,
  creationDate: string,
}

export const MediumFlexiblePostCard = (props: ICardProps) => {
  const { image, image_webp, videoThumb, title, href, creationDate, excerpt } = props
  const imageUrl = image_webp || image

  return (
    <MediumFlexibleCard>
      <BaseContainer href={href}>
        <MediumCardImg src={imageUrl} alt={replaceRawHtmlEntities(title)} loading="lazy"/>
        <MediumContent>
          <MediumCardText>{replaceRawHtmlEntities(title)}</MediumCardText>
          <MediumExcerpt>{replaceRawHtmlEntities(excerpt)}</MediumExcerpt>
        </MediumContent>
        <MediumCreationDate>
          <CreationDate>{creationDate}</CreationDate>
        </MediumCreationDate>
      </BaseContainer>
    </MediumFlexibleCard>
  )
}

interface ISmallAssetCardProps {
  href: string
  title: string
  image: string
}

export const SmallAssetCard = (props: ISmallAssetCardProps) => (
  <AssetCard>
    <BaseContainer href={props.href}>
      <AssetCardImgContainer>
        <AssetCardImg src={props.image} alt={replaceRawHtmlEntities(props.title)} loading="lazy"/>
        <AssetCardImgOverlay />
      </AssetCardImgContainer>
      <AssetContent>
        <SmallCardText>{replaceRawHtmlEntities(props.title)}</SmallCardText>
        <SmallCategory>BAIXAR</SmallCategory>
      </AssetContent>
    </BaseContainer>
  </AssetCard>
)

export const MediumPostCard = (props: IMediumCardProps) => {
  const { image, image_webp, title, href, creationDate, excerpt } = props
  const imageUrl = image_webp || image

  return (
    <MediumCard noShadowOnOver={props.noShadowOnOver}>
      <BaseContainer href={href}>
        <MediumCardImg src={imageUrl} alt={replaceRawHtmlEntities(title)} loading="lazy"/>
        <MediumContent>
          <MediumCardText>{replaceRawHtmlEntities(title)}</MediumCardText>
          <MediumExcerpt>{replaceRawHtmlEntities(excerpt)}</MediumExcerpt>
        </MediumContent>
        <MediumCreationDate>
          <CreationDate>{creationDate}</CreationDate>
        </MediumCreationDate>
      </BaseContainer>
    </MediumCard>
  )
}

export const PressReleasePostCard = (props: IPressCardProps) => {
  const { image, image_webp, title, href, excerpt, creationDate } = props
  const imageUrl = image_webp || image

  return (
    <PressReleaseCard>
      <PressReleaseContainer href={href}>
        <PressReleaseCardImg>
          <img src={imageUrl} alt={replaceRawHtmlEntities(title)} loading="lazy"/>
        </PressReleaseCardImg>
        <PressReleaseContent>
          <PressReleaseCardText>{replaceRawHtmlEntities(title)}</PressReleaseCardText>
          <HighlightExcerpt>{replaceRawHtmlEntities(excerpt)}</HighlightExcerpt>
          <CreationDate>{creationDate}</CreationDate>
        </PressReleaseContent>
      </PressReleaseContainer>
    </PressReleaseCard>
  )
}