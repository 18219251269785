export const replaceRawHtmlEntities = (text?: string): string => {
  if (!text) return ''

  let newText = ''
  newText = text.replaceAll('&#8220;', '\"')
  newText = newText.replaceAll('&#8221;', '\"')
  newText = newText.replaceAll('[&hellip;]', '...')
  newText = newText.replaceAll('&#8211;', '-')
  newText = newText.replaceAll('&#038;', '&')
  newText = newText.replaceAll('&#8216;', '\'')
  newText = newText.replaceAll('&#8217;', '\'')
  return newText

}